// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.first};

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;

  .LoadingDetails {
    max-width: 280px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &__image {
      img {
        width: 100%;
      }
    }

    &__spin {
      div {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 3px solid ${COLORS.white};
        border-left-color: ${COLORS.second};
        animation: spinner 1s linear infinite;
      }
    }
  }

  @keyframes spinner {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;