// LIBs
import styled from "styled-components";

// INCLUDEs
import { SCREENS, COLORS, FUNC } from "../../../core/StyleSheets/includes";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${props => props.widthheader}px);
  border-right: 1px solid ${COLORS.black_l};
  background-color: ${COLORS.white};
  transform: translateX(-100%);
  transition: all ease 0.8s;

  position: fixed;
  top: ${props => props.widthheader}px;
  left: 0;

  ${props => props.show ? `
    transform: translateX(0);
  ` : ""}

  @media ${SCREENS.tb_min} {
    max-width: 280px;
    height: auto;
    transform: translateX(0);

    position: unset;
  }

  button {
    padding: 20px 3px;
    background-color: ${COLORS.white};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: translate(100%, -50%);
    transition: all ease 0.8s;
    border: 1px solid ${COLORS.black_l};
    border-left: 0;

    position: absolute;
    top: 50%;
    right: 0;

    ${props => props.show ? `
      transform: translate(0, -50%);
      border-radius: 0;
      border-right: 0;
      border-left: 1px solid ${COLORS.black_l};
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    ` : ""}

    svg {
      transition: all ease 0.8s;

      ${props => props.show ? `
        transform: rotate(180deg);
      ` : ""}
    }

    @media ${SCREENS.tb_min} {
      display: none;
    }
  }

  ul {
    overflow-y: auto;
    height: calc(100vh - ${props => props.widthheader}px);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media ${SCREENS.tb_min} {
      height: auto;
    }


    li {
      display: flex;
      cursor: pointer;

      &:hover {
        &::before {
          width: 5px;
        }
      }

      &.active-link {
        &::before {
          width: 5px;
        }
      }

      a {
        padding: 10px;
        ${FUNC.font(16, 600, COLORS.black)}
      }

      &::before {
        content: '';
        width: 0;
        background-color: ${COLORS.second};
        transition: all ease 0.5s;

        display: block;
      }
    }
  }
`;
