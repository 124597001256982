// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.section`
  padding-top: 40px;

  @media ${SCREENS.dsS_min} {
    padding-top: 120px;
  }

  .AboutContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    &__image {
      width: 100%;

      position: relative;
        z-index: 1;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__text {
      width: 100%;
      margin-top: -15vw;

      .TextsArea {
        max-width: 848px;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        padding-top: 60px;
        padding-bottom: 30px;
        border-radius: 10px;
        background-color: ${COLORS.sixth_rgba};

        position: relative;
        z-index: 1;

        @media ${SCREENS.tb_min} {
          padding: 40px;
          padding-top: 80px;
          padding-bottom: 60px;
        }

        &__title {
          text-align: center;
          margin-bottom: 20px;

          h2 {
            font-size: 7vw;
            font-weight: 700;
            line-height: 1.1;
            text-transform: uppercase;

            @media ${SCREENS.tb_min} {
              ${FUNC.font(18, 700)}
            }
          }
        }

        &__text {
          p {
            ${FUNC.font(14, 500, COLORS.black, 24)}

            @media ${SCREENS.tb_min} {
              ${FUNC.font(16, 500, COLORS.black, 26)}
            }
          }
        }
      }
    }
  }
`;