// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.section`
  padding: 40px 0;
  background-color: #FAFAFA;

  @media ${SIZES.dsS} {
    padding: 80px 0;
  }

  .ContactContainer {
    max-width: 450px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    @media ${SIZES.tb} {
      max-width: 1024px;
      
      display: flex;
      gap: 40px;
    }

    &__title {
      margin-bottom: 40px;

      @media ${SIZES.tb} {
        width: calc(50% - 20px);
        margin-bottom: 0;
      }

      h4 {
        max-width: 57vw;
        ${FUNC.font('6vw', 400, '#000', '8vw')}
        text-align: left;

        @media ${SIZES.mbB} {
          max-width: 242px;
          font-size: 25px;
          line-height: 35px;
        }

        @media ${SIZES.dsS} {
          max-width: 442px;
          font-size: 48px;
          line-height: 58px;
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: column;

      @media ${SIZES.tb} {
        width: calc(50% - 20px);
        margin-bottom: 0;
      }

      @media ${SIZES.dsS} {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
      }

      .InputLabel {
        margin-bottom: 20px;
        
        @media ${SIZES.dsS} {
          margin-bottom: 0;
        }

        &:nth-of-type(1) {
          @media ${SIZES.dsS} {
            width: calc(50% - 10px);
          }
        }

        &:nth-of-type(2) {
          @media ${SIZES.dsS} {
            width: calc(50% - 10px);
          }
        }
      }

      .InputMaskLabel {
        margin-bottom: 20px;

        @media ${SIZES.dsS} {
          margin-bottom: 0;
        }
      }

      .TextAreaLabel {
        margin-bottom: 40px;

        @media ${SIZES.dsS} {
          margin-bottom: 20px;
        }
      }

      .Button {
        margin-bottom: 20px;
        width: fit-content;
        min-width: 163px;
        padding: 15px 30px;
      }

      .ContactInfo {
        p {
          ${FUNC.font('3vw', 400, COLORS.first)};

          @media ${SIZES.mbB} {
            font-size: 12px;
            line-height: 14px;
          }

          @media ${SIZES.dsS} {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
`;
