// GIF
import bannerGif from "../../../assets/animacao_about.gif";

// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container>
      <div className="TextContainer">
        <h1>Política de Privacidade da Mdsystemweb</h1>
        <h2>Última Atualização: 27/09/2023</h2>
        <p>A <strong>Mdsystemweb</strong>, doravante referida como "nós", "nosso" ou "Mdsystemweb", está comprometida em proteger a privacidade e a segurança das informações pessoais que você, doravante referido como "você" ou "usuário", pode fornecer durante o uso dos nossos serviços de desenvolvimento de websites, sistemas web, lojas virtuais e outras interações com o nosso website.</p>
        <p>Esta política de privacidade descreve como coletamos, usamos, compartilhamos e protegemos suas informações pessoais de acordo com a Lei Geral de Proteção de Dados (LGPD) no Brasil.</p>
        <strong>1. Informações que Coletamos</strong>
        <p>Podemos coletar informações pessoais que você fornece voluntariamente, como nome, endereço de e-mail, número de telefone, endereço e outras informações semelhantes, quando:</p>
        <ul>
          <li><p>Você solicita nossos serviços de desenvolvimento de websites, sistemas web, lojas virtuais ou outros serviços relacionados.</p></li>
          <li><p>Você se inscreve para receber nossos boletins informativos ou atualizações.</p></li>
          <li><p>Você envia informações por meio de formulários de contato ou outras formas de comunicação.</p></li>
        </ul>
        <p>Além disso, nosso site pode coletar informações automaticamente por meio de cookies e tecnologias semelhantes. Essas informações podem incluir, entre outras coisas, seu endereço IP, tipo de navegador, sistema operacional, páginas visitadas e tempo gasto em nosso site. Você pode gerenciar as configurações de cookies do seu navegador.</p>
        <strong>2. Como Usamos Suas Informações</strong>
        <p>Utilizamos suas informações pessoais para:</p>
        <ul>
          <li><p>Fornecer os serviços de desenvolvimento de websites, sistemas web, lojas virtuais ou outros serviços que você solicitou.</p></li>
          <li><p>Enviar comunicações de marketing relevantes, caso você tenha consentido.</p></li>
          <li><p>Melhorar e personalizar sua experiência em nosso site.</p></li>
          <li><p>Responder a perguntas ou solicitações que você nos envia.</p></li>
          <li><p>Cumprir obrigações legais.</p></li>
        </ul>
        <strong>3. Compartilhamento de Informações</strong>
        <p>Não vendemos ou alugamos suas informações pessoais para terceiros. Podemos compartilhar suas informações com:</p>
        <ul>
          <li><p>Prestadores de serviços que nos auxiliam na operação de nossos serviços de desenvolvimento web.</p></li>
          <li><p>Autoridades governamentais, conforme exigido por lei ou para proteger nossos direitos legais.</p></li>
        </ul>
        <strong>4. Proteção de Suas Informações</strong>
        <p>Empregamos medidas de segurança adequadas para proteger suas informações pessoais contra acesso não autorizado ou uso indevido.</p>
        <strong>5. Seus Direitos de Privacidade</strong>
        <p>Você tem o direito de:</p>
        <ul>
          <li><p>Acessar e atualizar suas informações pessoais.</p></li>
          <li><p>Optar por não receber comunicações de marketing.</p></li>
          <li><p>Excluir suas informações pessoais.</p></li>
        </ul>
        <strong>6. Alterações nesta Política de Privacidade</strong>
        <p>Podemos atualizar esta política de privacidade periodicamente. A data da última atualização será indicada no topo desta página.</p>
        <strong>7. Como Entrar em Contato</strong>
        <p>Se tiver dúvidas sobre esta política de privacidade ou desejar exercer seus direitos de privacidade, entre em contato conosco em <a href="mailto:contato@mdsystemweb.com.br">contato@mdsystemweb.com.br</a></p>
        <p>Esta política de privacidade foi atualizada pela última vez em <strong>27/09/2023</strong>.</p>
      </div>
    </C.Container>
  )
}