// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  .Page-Api-Step {
    &__Step {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      &--Count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        background-color: ${COLORS.white};
        border: 3px solid ${COLORS.second};
        border-radius: 100%;

        @media ${SCREENS.tbV_min}{
          width: 120px;
          height: 120px;
        }

        p {
          ${FUNC.font(32, 700, COLORS.first)}

          @media ${SCREENS.tbV_min}{
            ${FUNC.font(50, 700, COLORS.first)}
          }
        }
      }

      &--Content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        width: 220px;
      }

      &--Title {
        ${FUNC.font(32, 700, COLORS.first)}
        text-align:center;
        
      }

      &--Subtitle p{
        ${FUNC.font(16, 400, COLORS.black)}
        text-align:center;
        
      }
    }
  }
`;
