// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .Page-Api-Advantages {
    &__Content {
      padding: 16px 32px;
      max-width: 1280px;
      width: 100%;
      gap: 16px;

      @media ${SCREENS.dsS_min} {
        padding: 24px 16px;
        gap: 0;
      }
    }

    &__Icon {
      width: 48px;
      height: 48px;
    }

    &__Arrow {
      position: absolute;
      right: 32px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: #fff;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);

      svg {
        width: 24px;
        height: 24px;
      }

      @media ${SCREENS.dsS_min} {
        display: none;
      }
    }
  }

  .Slider-Dots {
    position: absolute;
    bottom: -40px;
    display: flex !important;
    width: 100%;
    height:6px;
    overflow:hidden;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    border-radius:6px;

    & li {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
      background-color:${COLORS.sixth};

      &.slick-active button{
        opacity: 1;
        background-color: ${COLORS.first};
      }

      & button {
        border-radius:6px;
        font-size: 0;
        line-height: 0;
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
      }
    }
  }
`;
