// LIBs
import axios from "axios";

// CONFIG
import config from "../config/App.config";

const apiDash = axios.create({
  baseURL: config.reqUrl,
  headers: {
    "Authorization": `Bearer ${config.token_api}`,
  },
});

export const getMenu = async (url_key) => {
  try {
    const req = await apiDash.get(`/menus`);

    return req;
  } catch(err) {
    console.error(err);
  }
}

export const getBlocks = async (url_key) => {
  try {
    const req = await apiDash.get(`/block`);

    return req;
  } catch(err) {
    console.error(err);
  }
}

export const getBlock = async (id, url_key) => {
  try {
    const req = await apiDash.get(`/block/${id}`);

    return req;
  } catch(err) {
    console.error(err);
  }
}

export const sendDash = async (params) => {
  try {
    const req = await apiDash.post("/contact", params);

    return req;
  } catch (err) {
    console.error(err);
  }
}

export const getPlans = async () => {
  try {
    const req = await axios.get(`https://api.developer.pageapi.mdsystemweb.com.br/api/plans`);

    return req;
  } catch(err) {
    console.error(err);
  }
}
