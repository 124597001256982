// LIBs
import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";

// ICONs
import { FiChevronRight } from "react-icons/fi";

// STYLEs
import * as C from "./styles";

const Links = [
    {
        label: "Introdução",
        name: "introduction",
    },
    {
        label: "Navegação e Cabeçalho",
        name: "layout",
    },
    {
        label: "Home",
        name: "home",
    },
    {
        label: "Perfil",
        name: "profile",
    },
    {
        label: "Grupos",
        name: "groups",
    },
    {
        label: "Usuários",
        name: "users",
    },
    {
        label: "Webstores",
        name: "webstores",
    },
    {
        label: "Menus",
        name: "menus",
    },
    {
        label: "Blocos",
        name: "blocks",
    },
    {
        label: "Conteúdo",
        name: "content",
    },
    {
        label: "Galeria",
        name: "gallery",
    },
    {
        label: "Contatos",
        name: "contacts",
    },
    {
        label: "Configurações",
        name: "settings",
    },
    {
        label: "Api",
        name: "api",
    },
    {
        label: "Tabelas",
        name: "tables",
    },
];

const pageLinks = [
    { link: "a#upgrade", page: "introduction", scrollTo: "#como-fazer-um-upgrade-de-plano-admin-" },
    { link: "a#menu-de-navegação", page: "layout", scrollTo: "#menu-de-navega-o" },
    { link: "a#cabeçalho", page: "layout", scrollTo: "#cabe-alho" },
    {
        link: "a#visualização-de-webstores",
        page: "layout",
        scrollTo: "h3#visualiza-o-de-webstores",
    },
    {
        link: "a#informações-de-administrativas",
        page: "layout",
        scrollTo: "h3#informa-es-administrativas",
    },
    { link: "a#seletor-de-webstores", page: "layout", scrollTo: "h3#seletor-de-webstores" },
    { link: "a#menu-do-cabeçalho-⋮", page: "layout", scrollTo: "h3#menu-do-cabe-alho-" },
    { link: "a#perfil", page: "profile" },
    { link: "a#como-editar-o-perfil", page: "profile", scrollTo: "#como-editar-o-perfil-" },
    { link: "a#grupos", page: "groups", scrollTo: "#grupos" },
    { link: "a#usuários", page: "users", scrollTo: "#usu-rios" },
    {
        link: "a#como-cadastrar-meu-primeiro-usuário",
        page: "introduction",
        scrollTo: "#como-cadastrar-meu-primeiro-usu-rio-",
    },
    { link: "a#webstores", page: "webstores" },
    { link: "a#tokens", page: "webstores", scrollTo: "h3#tokens" },
    { link: "a#menus", page: "menus" },
    { link: "a#blocos", page: "blocks" },
    { link: "a#adicionar-campo", page: "blocks", scrollTo: "h2#adicionar-campo" },
    {
        link: "a#quais-são-os-tipos-de-campos",
        page: "blocks",
        scrollTo: "h3#quais-s-o-os-tipos-de-campos-",
    },
    { link: "a#conteúdo", page: "content" },
    {
        link: "a#como-adicionar-ou-alterar-conteúdo-ao-meu-site",
        page: "content",
        scrollTo: "h2#como-adicionar-ou-alterar-um-conte-do-do-meu-site-",
    },
    { link: "a#galeria", page: "gallery" },
    { link: "a#contatos", page: "contacts" },
    {
        link: "a#como-visualizar-e-responder-mensagens",
        page: "contacts",
        scrollTo: "h2#como-visualizar-e-responder-mensagens",
    },
    { link: "a#api", page: "api" },
    { link: "a#como-conectar-a-api", page: "api", scrollTo: "h3#como-conectar-a-api-" },
    { link: "a#rotas", page: "api", scrollTo: "h3#rotas" },
    {
        link: "a#como-cadastrar-um-novo-item",
        page: "tables",
        scrollTo: "#como-cadastrar-um-novo-item-",
    },
    {
        link: "a#como-editar-um-item-da-tabela",
        page: "tables",
        scrollTo: "#como-editar-um-item-da-tabela-",
    },
    { link: "a#como-excluir-um-item", page: "tables", scrollTo: "#como-excluir-um-item-" },
];

export default (props) => {
    const [widthHeader, setWidthHeader] = useState(0);
    const [shownSidebar, setShownSidebar] = useState(false);
    const itemsRef = useRef([]);

    useEffect(() => {
        scrollTop();
        window.onresize = scrollTop;
    }, []);

    useEffect(() => {
        pageLinks.map((link) => {
            changePageOnClickSelector(link.link, link.page, link.scrollTo);
        });
    });

    function changePageOnClickSelector(selector, page, scroll = null) {
        [...document.querySelectorAll(selector)].map((el) =>
            el?.addEventListener("click", () => {
                const index = Links.map((e) => e.name).indexOf(page);
                itemsRef.current[index].click();

                if (scroll !== null) {
                    setTimeout(() => {
                        const yOffset = -125;
                        const element = document.querySelector(scroll);
                        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

                        window.scrollTo({ top: y, behavior: "smooth" });
                    }, 300);
                } else {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            })
        );
    }

    function scrollTop() {
        const header = document.querySelector("header");
        if (header.clientHeight !== widthHeader) setWidthHeader(header.clientHeight);
    }

    return (
        <C.Container show={shownSidebar ? 1 : 0} widthheader={widthHeader}>
            <ul>
                {Links.map((el, index) => (
                    <li
                        ref={(el) => (itemsRef.current[index] = el)}
                        className={el.name === props.activeLink ? "active-link" : ""}
                        onClick={() => {
                            props.onActiveLink(el.name);
                            if (shownSidebar) setShownSidebar(false);
                            window.scrollTo(0,0)
                            ReactGA.event({
                                action: `Documentação (${el.label})`,
                                category: "click",
                            });
                        }}
                        key={index}
                    >
                        <a href={`#${el.name}!`}>{el.label}</a>
                    </li>
                ))}
            </ul>

            <button
                onClick={() => {
                    setShownSidebar(!shownSidebar);
                }}
            >
                <FiChevronRight />
            </button>
        </C.Container>
    );
};
