// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.div`
  @media ${SCREENS.tb_min} {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .FAQDetailsBanner {
    margin-bottom: 20px;

    @media ${SCREENS.tb_min} {
      margin-bottom: 40px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .FAQDetailsTitle {
    padding: 0 16px;
    margin-bottom: 20px;

    @media ${SCREENS.tb_min} {
      padding: 0
    }

    h1 {
      font-size: 7vw;
      font-weight: 700;
      line-height: 1.1;

      @media ${SCREENS.mbB_min} {
        ${FUNC.font(26, 700)}
      }
    }
  }

  .FAQDetailsList {
    padding: 0 16px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    @media ${SCREENS.tb_min} {
      padding: 0;
      gap: 30px;
    }
  }
`;
