// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.footer`
  background-color: ${COLORS.first}; 

  .FooterInfo {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 20px 20px;
    
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media ${SCREENS.tb_min} {
      padding: 60px 40px 40px;
      
      flex-direction: row;
      justify-content: space-between;
    }

    &__col {
      .FooterTitle {
        text-align: center;
        margin-bottom: 5px;

        h4 {
          ${FUNC.font(16, 700, COLORS.white)}
          text-transform: uppercase;
          line-height: 1.4;
        }
      }

      .FooterText {
        text-align: center;

        p {
          ${FUNC.font(12, 400, COLORS.white)}
          line-height: 1.4;
        }

        a {
          margin-top: 20px;
          color: ${COLORS.second};

          display: block;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .FooterIcons {
        display: flex;
        justify-content: center;

        img {
          width: 40px;
        }
      }
    }
  }

  .FooterSecurity {
    padding: 20px 0;
    padding-bottom: 40px;

    @media ${SCREENS.tb_min} {
      padding-top: 0;
      padding-bottom: 80px;
    }

    &__container {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;

      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      @media ${SCREENS.tb_min} {
        flex-direction: row;

        justify-content: center;
      }
    }
  }

  .FooterCopywriter {
    background-color: ${COLORS.fourth};
    padding: 10px;
    text-align: center;

    p {
      ${FUNC.font(12, 400, COLORS.white)}
      line-height: 1.4;
    }
  }
`;
