// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.div`
  padding: 40px 30px;
  padding-bottom: 60px;
  border-radius: 30px;
  background-color: #FFF;
  box-shadow: 0 1px 4px rgba(255, 255, 255, 0.6);

  .CardAssessmentIcon {
    margin-bottom: 10px;

    span {
      font-size: 8vw;
      color: ${COLORS.first};

      @media ${SIZES.mbB} {
        font-size: 34px;
      }
    }
  }

  .CardAssessmentText {
    margin-bottom: 20px;

    p {
      ${FUNC.font('4vw', 200, '#000', '6vw')}

      @media ${SIZES.mbB} {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .CardAssessmentAuthor {
    margin-bottom: 10px;

    p {
      ${FUNC.font('4.5vw', 600)}

      @media ${SIZES.mbB} {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .CardAssessmentStars {
    display: flex;
    gap: 5px;

    span {
      font-size: 4vw;
      color: ${COLORS.first};

      @media ${SIZES.mbB} {
        font-size: 18px;
      }
    }
  }
`;
