// LIBS
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { jwtDecode } from "jwt-decode";

// Components
import Loading from "../../components/Loading";

// CONTAINERs
import Banner from "../../containers/Home/Banner";
import Services from "../../containers/Home/Services";
import Hilights from "../../containers/Home/Hilights";
import Product from "../../containers/Home/Product";
import About from "../../containers/Home/About";
import ContactUs from "../../containers/Home/ContactUs";
import Contact from "../../containers/Home/Contact";

// FORMs
import ContactForm from "../../forms/ContactForm";

// SERVICEs
import { getBlocks, sendDash } from "../../core/services/Api";

// UTILs
import { convertDataPageApiInObject } from "../../core/utils/CDPAIO-1.0.0";

// STYLEs
import * as C from "./styles";

const cacheHomeName = "@mdsystem:home";

function Home(props) {
  const navigate = useNavigate();

  const [ blocks, setBlocks ] = useState({});
  
  useEffect(() => {
    start();
  }, [])

  async function start() {
    props.onShownLoad();

    document.title = "Mdsystem Web";

    ReactGA.event({
      action: "Home",
      category: "load-page",
    });
    
    let cacheBlocks = window.localStorage.getItem(cacheHomeName);

    if (cacheBlocks) {
      cacheBlocks = JSON.parse(cacheBlocks);
      setBlocks(cacheBlocks);

      props.onHideLoad(false);
    }

    const req = await getBlocks("default");
    const reqDecoded = jwtDecode(req.data.token);

    if (req.status === 200 && reqDecoded.data.length > 0) {
      const objectData = convertDataPageApiInObject(reqDecoded.data)
      setBlocks(objectData);
      window.localStorage.setItem(cacheHomeName, JSON.stringify(objectData));
    }

    setTimeout(() => props.onHideLoad(false), 800);
  }

  function goHilight(index, title) {
    ReactGA.event({
      action: `Clicou ${title}`,
      category: "click",
    });

    navigate(`/hilights/${index}`);
  }

  async function sendEmail(...data) {
    const req = await sendDash({
      name: data[0],
      email: data[1],
      phone: data[2],
      subject: data[3],
      message: data[4],
    });

    ReactGA.event({
      action: `(${data[0]}) Enviou o formulário`,
      category: "send",
    });
    
    if (req.status === 200) {
      toast.success("Envio realizado com sucesso!");
    } 
  }
  
  return (
    <C.Container className="HomeView">
      { (blocks.banner && 
        (blocks.titulo_servicos && blocks.services) && 
        (blocks.titulo_destaque && blocks.destaques) && 
        (blocks.produtos_detalhes && blocks.produtos_lista) && blocks.about &&
        (blocks.titulo_fale_conosco && blocks.formas_de_contato) &&
        blocks.titulo_de_contato) ?
        <>
          <Banner data={blocks.banner} />
          <Services data={blocks.titulo_servicos} services={blocks.services} />
          <Hilights data={blocks.titulo_destaque} destaques={blocks.destaques} onTo={goHilight} />
          <Product data={blocks.produtos_detalhes} list={blocks.produtos_lista} />
          <About data={blocks.about} />
          <ContactUs data={blocks.titulo_fale_conosco} contactForms={blocks.formas_de_contato} />
          <Contact data={blocks.titulo_de_contato}>
            <ContactForm onSend={sendEmail} />
          </Contact>
        </> :
        props.onShownLoad()
      }
    </C.Container>
  )
}

export default Home;