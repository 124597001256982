// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container className="Card">
      <div className="CardContainer">
        <div className="CardContainer__icon">
          <div>
            <picture>
              <source srcSet={props.srcLite} type="image/webp"/>
              <source srcSet={props.src} type="image/png" />
              <img 
                src={props.src} 
                width={46} 
                height={46}
                alt={props.title} 
              />
            </picture>
          </div>
        </div>

        <div className="CardContainer__title">
          <p>{props.title}</p>
        </div>

        <div 
          className="CardContainer__text" 
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        />
      </div>
    </C.Container>
  )
}