// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media ${SCREENS.ds_min} {
    min-width: 272px;
  }

  .Advantage {
    &__Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      border-radius: 50%;
      background-color: ${COLORS.fourth};
    }

    &__Content {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
    }

    &__Title {
      ${FUNC.font(20, 600, COLORS.black)}
      text-align:center;
      line-height: 120%;

      @media ${SCREENS.ds_min} {
        ${FUNC.font(24, 600, COLORS.black)}
      }
    }

    &__Subtitle p{
      ${FUNC.font(14, 400, COLORS.black)}
      text-align:center;
      line-height: 120%;
      max-width: 219px;

      @media ${SCREENS.ds_min} {
        ${FUNC.font(16, 400, COLORS.black)}
      }
    }
  }
`;
