// STYLEs
import * as C from "./styles";

export default props => {
  let buttonClass = "Button";
  let linkExternalClass = "LinkExternal";

  if (props.className) buttonClass += ` ${props.className}`;

  switch(props.type) {
    case "link-external":
      return (
        <C.Container>
          <a 
            href={props.to} 
            target="_blank"
            className={linkExternalClass}
            onClick={props.onClick}
          >
            {props.label}
          </a>
        </C.Container>
      )
    default:
      return (
        <C.Container>
          <button className={buttonClass} onClick={props.onClick}>
            {props.label}
          </button>
        </C.Container>
      )
  }
}