// COMPONENTs
import Card from "../../../components/Card";

// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container id="services">
      <div className="ServicesContainer">
        <div className="ServicesContainer__title">
          <h1>{props.data.title}</h1>
        </div>

        <div className="ServicesContainer__list">
          { (props.services && props.services.length > 0) &&
            props.services.map((element, index) => (
              <Card 
                src={element.icon}
                srcLite={element.icon_lite}
                title={element.title}
                text={element.text}
                key={index}
              />
            ))
          }
        </div>
      </div>
    </C.Container>
  )
}