// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  width: 100%;
  .Page-Api-Plans {
    &__Plans {
      position: relative;
      width: 100%;
      gap: 24px;

      &--Container {
        width: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 16px;
      }
    }
  }

  .Slider-Dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    & li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      &.slick-active button:before {
        opacity: 1;
        color: ${COLORS.second};
      }

      & button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;

        &:before {
          font-family: "slick";
          font-size: 16px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          content: "•";
          text-align: center;
          opacity: 1;
          color: ${COLORS.sixth};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }
`;
