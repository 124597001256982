// COMPONENTs
import Button from "../Button"

// STYLEs
import * as C from "./styles";

const ariaLabels = {
  "Landing Page": "Descubra como as Landing Pages podem ajudar sua empresa",
  "One Page": "Saiba mais sobre as One Page",
  "Loja Virtual": "Saiba mais sobre a criação de uma loja virtual",
  "Suporte Técnico": "Saiba mais sobre nosso serviço de suporte técnico",
}

export default props => {
  return (
    <C.Container className="HilightItem">
      <div className="HilightItemContainer">
        <div className="HilightItemContainer__title">
          <p>{props.title}</p>
        </div>

        <div className="HilightItemContainer__image">
          <picture>
            <source srcSet={props.srcLite} type="image/webp"/>
            <source srcSet={props.src} type="image/png" />
            <img 
              src={props.src} 
              width={186}
              height={129}
              alt={props.title} 
            />
          </picture>
        </div>

        <div className="HilightItemContainer__button">
          <Button 
            label="VER MAIS" 
            onClick={props.onTo} 
            button={{
              "aria-label": ariaLabels[props.title]
            }}
          />
        </div>
      </div>
    </C.Container>
  )
}