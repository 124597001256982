// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  width: 100%;

  display: flex;

  .Button,
  .LinkExternal,
  .Link {
    width: 100%;
    padding: 10px 30px;
    border-radius: 999px;
    background-color: ${COLORS.second};
    ${FUNC.font(14, 600, COLORS.white)}
    line-height: 1.4;
    transition: all ease .5s;

    ${props => props.secondary ? `
      background-color: ${COLORS.first};
    ` : ''}

    &:hover {
      background-color: ${COLORS.first};

      ${props => props.secondary ? `
        background-color: ${COLORS.second};
      ` : ''}
    }

    @media ${SCREENS.tb_min} {
      ${FUNC.font(16, 600, COLORS.white)}
      padding: 13px 33px;
    }
  }

  .LinkExternal,
  .Link {
    text-align: center;

    display: block;
  }
`;
