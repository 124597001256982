//LIBs
import React from "react";

//STYLEs
import { Container } from "./styles";

//ASSETs
import CheckedSquare from "../../assets/check-square.svg";

const PlanCard = ({ plan, style }) => {
  let currencyFormat = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return (
    <Container>
      <div className="Plan-Card__Container" style={style}>
        <div className="Plan-Card__Header">
          <p>{plan.name}</p>
        </div>
        <div className="Plan-Card__Content">
          <div className="Plan-Card__Prices">
            <p className="Plan-Card__Value">
              {currencyFormat.format(plan.price)}
            </p>
            <p className="Plan-Card__Period">por mês</p>
          </div>
          <div className="Plan-Card__Advantages-List">
            {plan.json_items &&
              plan.json_items.map(
                (adv, index) =>
                  adv.is_active && (
                    <div
                      key={index}
                      className={`Plan-Card__Advantage ${index % 2 && "white"}`}
                    >
                      <div className="Plan-Card__Advantage--content">
                        <img src={CheckedSquare} alt="Icone de Check" />
                        <p>{adv.item}</p>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PlanCard;
