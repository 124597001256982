//LIBs
import Slider from "react-slick";
import React, { useRef } from "react";

//STYLEs
import { Container } from "./styles";

//COMPONENTs
import AdvantageCard from "../../../components/AdvantageCard";
import Arrow from "../../../components/ArrowButton";

//SERVICEs
import { convertDataPageApi } from "../../../core/services/dataPageapi";

const PageApiAdvantages = (props) => {
  const sliderRef = useRef();

  function next() {
    sliderRef.current.slickNext();
  }

  function previous() {
    sliderRef.current.slickPrev();
  }

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    dotsClass: "Slider-Dots",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Container>
      <div className="Page-Api-Advantages__Content">
        <Arrow onClick={next} />
        <Arrow onClick={previous} type="left" />
        <Slider ref={sliderRef} {...settings}>
          {props.data.map((vantagem, index) => (
            <AdvantageCard
              key={index}
              icon={vantagem.icon}
              title={vantagem.title}
              subtitle={vantagem.textarea}
            />
          ))}
        </Slider>
      </div>
    </Container>
  );
};

export default PageApiAdvantages;
