// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.section`
  padding: 40px 0;
  background: linear-gradient(145deg, ${COLORS.second_dark} -15%, ${COLORS.first} 30%, ${COLORS.first_light} 100%);

  @media ${SIZES.tb} {
    padding: 80px 0;
  }

  .AssessmentsContainer {
    max-width: 450px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    @media ${SIZES.tb} {
      max-width: 1024px;
    }

    &__title {
      margin-bottom: 30px; 

      @media ${SIZES.tb} {
        margin-bottom: 60px;
      }

      h4 {
        max-width: 70vw;
        ${FUNC.font('9vw', 400, '#FFF', '11vw')}

        @media ${SIZES.mbB} {
          max-width: 297px;
          font-size: 38px;
          line-height: 48px;
        }

        @media ${SIZES.tb} {
          max-width: 307px;
          font-size: 48px;
          line-height: 58px;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media ${SIZES.tb} {
        flex-direction: row;
        gap: 20px;
      }
    }
  }
`;
