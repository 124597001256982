// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container>
      <div className="HilightInfoContainer">
        <div className="HilightInfoContainer__image">
          <picture>
            <source srcSet={props.data.image_lite} type="image/webp"/>
            <source srcSet={props.data.image} type="image/png" />
            <img 
              src={props.data.image} 
              width={425}
              height={262}
              alt={props.data.title} 
            />
          </picture>
        </div>

        <div className="HilightInfoContainer__details">
          <div className="HilightTitle">
            <h1>{props.data.title}</h1>
          </div>

          <div 
            className="HilightText" 
            dangerouslySetInnerHTML={{ __html: props.data.about }} 
          />
        </div>
      </div>
    </C.Container>
  );
}