const appConfig = {
  // PRODUCTION
  development: {
    baseUrl: 'http://localhost:3000',
    reqUrl: 'https://api.pageapi.mdsystemweb.com.br/api/v2',
    token_api: 'X8l7jR5alTmsTc+HFkIClkvezKR3IBtT7CZssDx9npI=',
    pathname: '/',
  },
  production: {
    baseUrl: 'https://www.mdsystemweb.com.br',
    reqUrl: 'https://api.pageapi.mdsystemweb.com.br/api/v2',
    token_api: 'X8l7jR5alTmsTc+HFkIClkvezKR3IBtT7CZssDx9npI=',
    pathname: '/',
  },
  // HOMOLOG
  // development: {
  //   baseUrl: 'http://localhost:3000',
  //   reqUrl: 'https://api.pageapi.mdsystemweb.com.br/api/v2',
  //   token_api: '77c5cc04767544812df4f38182051c51',
  //   pathname: '/',
  // },
  // production: {
  //   baseUrl: 'https://www.mdsystemweb.com.br',
  //   reqUrl: 'https://api.pageapi.mdsystemweb.com.br/api/v2',
  //   token_api: '77c5cc04767544812df4f38182051c51',
  //   pathname: '/hml/mdsystemweb/',
  // },
};

export default appConfig[process.env.NODE_ENV];
