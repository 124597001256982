// COMPONENTs
import Button from "../../../components/Button";
import ProductItem from "../../../components/ProductItem";

// SERVICEs
import { convertDataPageApi } from "../../../core/services/dataPageapi";

// STYLEs
import * as C from "./styles";

export default (props) => {
  return (
    <C.Container id="product">
      <div className="ProductContainer">
        <div className="ProductContainer__title">
          <h3>{props.data.title}</h3>
        </div>

        <div className="ProductContainer__box">
          <div className="ProductBox">
            <div className="ProductBox__list">
              { props.list.length > 0 &&
                props.list.map((el, index) => (
                  <ProductItem 
                    iconName={el.icon_name} 
                    label={el.label} 
                    key={index}
                  />
                ))
              }
              <Button className="ProductBox__content--button--box--mobile" label="Ver Mais" type="link" to="/page-api"/>
            </div>

            <div className="ProductBox__content">
              <div className="ProductBox__content--title">
                <p>{props.data.title_box}</p>
              </div>

              <div 
                className="ProductBox__content--body"
                dangerouslySetInnerHTML={{ 
                  __html: props.data.text_box 
                }}
              />

              <div className="ProductBox__content--button">
                <Button className="ProductBox__content--button--box" label="Ver Mais" type="link" to="/page-api"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </C.Container>
  );
}