// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container className="CardFunctionalities">
      <div className="CardFunctionalitiesIcon">
        <span>{props.icon}</span>
      </div>

      <div className="CardFunctionalitiesTitle">
        <p>{props.title}</p>
      </div>

      <div 
        className="CardFunctionalitiesText" 
        dangerouslySetInnerHTML={{
          __html: props.text,
        }}
      />
    </C.Container>
  )
}