//LIBs
import React, { useRef } from "react";
import Slider from "react-slick";

//STYLEs
import { Container } from "./styles";

//COMPONENTs
import PlanCard from "../PlanCard";
import Arrow from "../ArrowButton";

const PlansSlider = (props) => {
    const sliderRef = useRef();

    const cardHeight =
        166 +
        Math.max(
            ...props.data.map((obj) => obj.json_items.filter((item) => item.is_active).length)
        ) *
            65;

    function next() {
        sliderRef.current.slickNext();
    }

    function previous() {
        sliderRef.current.slickPrev();
    }

    const settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        dotsClass: "Slider-Dots",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    return (
        <Container>
            {props.data && (
                <div className="Page-Api-Plans__Plans">
                    <Arrow onClick={previous} type="left" />
                    <Arrow onClick={next} />

                    <Slider ref={sliderRef} {...settings}>
                        {props.data
                            .sort((a, b) => a.price - b.price)
                            .map((card, index) => (
                                <div key={index} className="Page-Api-Plans__Plans--Container">
                                    <PlanCard plan={card} style={{ height: cardHeight }} />
                                </div>
                            ))}
                    </Slider>
                </div>
            )}
        </Container>
    );
};

export default PlansSlider;
