// GIF
import bannerGif from "../../../assets/animacao_about.gif";

// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container id="about">
      <div className="AboutContainer">
        <div className="AboutContainer__image">
          <img 
            src={bannerGif} 
            width={385}
            height={295}
            alt={props.data.title} 
          />
        </div>

        <div className="AboutContainer__text">
          <div className="TextsArea">
            <div className="TextsArea__title">
              <h2>{props.data.title}</h2>
            </div>

            <div 
              className="TextsArea__text" 
              dangerouslySetInnerHTML={{
                __html: props.data.textarea
              }}
            />
          </div>
        </div>
      </div>
    </C.Container>
  )
}