// ICONs
import { BsChevronDown } from "react-icons/bs";

// STYLEs
import * as C from "./styles";

export default props => {
  let faqCardClass = "";

  if (props.show) faqCardClass += "active-faq-card";

  return (
    <C.Container 
      aria-label="Clique para expandir e visualizar a resposta" 
      className={faqCardClass} 
    >
      <div className="FAQCardTitle" onClick={props.onClick}>
        <p>{props.title}</p>
        <BsChevronDown />
      </div>

      <div 
        className="FAQCardText" 
        dangerouslySetInnerHTML={{ __html: props.body }}
      />
    </C.Container>
  )
}