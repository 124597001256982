// STYLEs
import * as C from "./styles";

export default props => {
  const headerDefault = document.querySelector("header.Header");

  let headerClass = "HeaderPromotional";
  let headerBtnClass = "HeaderPromotionalContainer__btn";
  let headerNavClass = "HeaderPromotionalContainer__nav";

  if (props.showMenu) {
    headerBtnClass += " active-btn-menu";
    headerNavClass += " active-menu";
  }

  if (props.fixed) headerClass += " fixed-menu";

  let banMenu = ["Avaliações"];

  return (
    <C.Container 
      className={headerClass} 
      style={{
        top: props.fixed ? headerDefault.clientHeight : 0,
      }}
      onMouseLeave={() => props.onToggleMenu(false)}
    >
      <div className="HeaderPromotionalContainer">
        <div className={headerBtnClass}>
          <button className="BtnMenu" onClick={() => props.onToggleMenu(!props.showMenu)}>
            <div className="BtnMenu__bar" />
            <div className="BtnMenu__bar" />
            <div className="BtnMenu__bar" />
          </button>
        </div>

        <nav className={headerNavClass}>
          <ul>
            { props.data.length > 0 &&
              props.data.map((el, index) => {
                if (!banMenu.includes(el.title)) {
                  return (
                    <li key={index}>
                      <span 
                        className={el.link === props.currentAction ? "active-action" : ""}
                        onClick={() => props.onToScroll(el.link)}
                      >
                        {el.title}
                      </span>
                    </li>
                  )
                } else {
                  return false;
                }
              }) 
            }
          </ul>
        </nav>
      </div>
    </C.Container>
  )
}