// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.main`
  flex: 1;
  padding-top: 0;
  padding-bottom: 0;
`;
