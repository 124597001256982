// LIBs
import InputMask from "react-input-mask";

// STYLEs
import * as C from "./styles";

export default props => {
  switch(props.type) {
    case "textarea":
      return (
        <C.Container className="LabelTextArea">
          <span>{props.label}</span>
          <textarea 
            value={props.value} 
            onChange={props.onChange} 
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            {...props.textarea}
          />
        </C.Container>
      )
    case "mask":
      return (
        <C.Container className="LabelMask">
          <span>{props.label}</span>
          <InputMask 
            mask={props.mask}
            value={props.value} 
            onChange={props.onChange}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            {...props.inputMask}
          />
        </C.Container>
      )
    case "checkbox":
      return (
        <C.Container className="LabelCheck">
          <input type="checkbox" checked={props.value} onChange={props.onChange} />
          <div />
          <span>{props.label}</span>
        </C.Container>
      )
    default:
      return (
        <C.Container className="LabelInput">
          <span>{props.label}</span>
          <input 
            type={props.type} 
            value={props.value} 
            onChange={props.onChange}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            {...props.input}
          />
        </C.Container>
      )
  }
}