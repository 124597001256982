// LIBs
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

// IMAGEs
import instagram from "../../assets/instagram.png";
import instagramLite from "../../assets/instagram.webp";
import facebook from "../../assets/facebook.png";
import facebookLite from "../../assets/facebook.webp";
import linkedin from "../../assets/linkedin.png";
import linkedinLite from "../../assets/linkedin.webp";
import googleSecurity from "../../assets/google-security.png";
import googleSecurityLite from "../../assets/google-security.webp";
import conexaoSegura from "../../assets/conexao_segura.png";
import conexaoSeguraLite from "../../assets/conexao_segura.webp";

// STYLEs
import * as C from "./styles"

export default (props) => {
  function sendGA(platform) {
    ReactGA.event({
      action: platform,
      category: "click",
    });
  }

  return (
    <C.Container>
      <div className="FooterHeader">
        <div className="FooterHeader__container">
          <div className="FooterHeader__container--item">
            <div className="FooterDetails">
              <h4>Atendimentos</h4>
              <p>Segunda à Sexta: 08:00 - 18:00</p>
            </div>

            <div className="FooterDetails">
              <h4>redes sociais</h4>
              <div className="FooterDetails__list">
                <a 
                  href="https://www.instagram.com/mdsystemweb/" 
                  target="_blank" 
                  className="animate-link"
                >
                  <picture>
                    <source srcSet={instagramLite} type="image/webp"/>
                    <source srcSet={instagram} type="image/png" />
                    <img 
                      src={instagram} 
                      width={40} 
                      height={40} 
                      alt="Instagram" 
                      onClick={() => sendGA("Instagram")}
                    />
                  </picture>
                </a>
                <a 
                  href="https://www.facebook.com/mdsystemweb" 
                  target="_blank" 
                  className="animate-link"
                >
                  <picture>
                    <source srcSet={facebookLite} type="image/webp"/>
                    <source srcSet={facebook} type="image/png" />
                    <img 
                      src={facebook} 
                      width={40} 
                      height={40} 
                      alt="Facebook" 
                      onClick={() => sendGA("Facebook")}
                    />
                  </picture>
                </a>
                <a 
                  href="https://www.linkedin.com/company/mdsystem-web/about/" 
                  target="_blank"
                  className="animate-link"
                >
                  <picture>
                    <source srcSet={linkedinLite} type="image/webp"/>
                    <source srcSet={linkedin} type="image/png" />
                    <img 
                      src={linkedin}
                      width={40} 
                      height={40} 
                      alt="LinkedIn" 
                      onClick={() => sendGA("LinkedIn")}
                    />
                  </picture>
                </a>
              </div>
            </div>
          </div>

          <div className="FooterHeader__container--item">
            <div className="FooterDetails">
              <h4>contato</h4>
              <p>(47)99198-2664</p>
              <p>contato@mdsystemweb.com.br</p>
              <p>Rua Francisco haag – Nº 600 – Bairro Rio Branco - Brusque/SC</p>
            </div>
          </div>

          <div className="FooterHeader__container--item">
            <div className="FooterDetails">
              <h4>Certificações</h4>
              <div className="FooterDetails__list">
                <a 
                  href="https://transparencyreport.google.com/safe-browsing/search?url=mdsystemweb.com.br&hl=pt_BR"
                  target="_blank"
                >
                  <picture>
                    <source srcSet={googleSecurityLite} type="image/webp"/>
                    <source srcSet={googleSecurity} type="image/png" />
                    <img 
                      src={googleSecurity}
                      width={200} 
                      alt="GoogleSecurity" 
                      onClick={() => sendGA("GoogleSecurity")}
                    />
                  </picture>
                </a>
                <a 
                  href="https://www.sslshopper.com/ssl-checker.html#hostname=mdsystemweb.com.br"
                  target="_blank"
                >
                  <picture>
                    <source srcSet={conexaoSeguraLite} type="image/webp"/>
                    <source srcSet={conexaoSegura} type="image/png" />
                    <img 
                      src={conexaoSegura}
                      width={200} 
                      alt="ConexaoSegura" 
                      onClick={() => sendGA("ConexaoSegura")}
                    />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="FooterBottom">
        <div className="FooterBottom__container">
          <p>©2020-2023 Mdsystem Web - Todos os direitos reservados</p>

          <Link to="/privacy-policy">Politica de Privacidade</Link>
        </div>
      </div>
    </C.Container>
  )
}
