// COMPONENTs
import CardFunctionalities from "../../components/CardFunctionalities"

// ICONs
import * as FaIcon from "react-icons/fa";

// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container className="Functionalities">
      <div className="FunctionalitiesContainer">
        { props.data.length > 0 &&
          <div className="FunctionalitiesContainer__title">
            <h3>{props.data[2].children[0][0].value}</h3>
          </div>
        }

        { props.data.length > 0 &&
          <div className="FunctionalitiesContainer__list">
            {
              props.data[2].children.map((functionalities, index) => {
                const Icon = FaIcon[functionalities[1].value];

                return (
                  <CardFunctionalities 
                    icon={<Icon />}
                    title={functionalities[2].value}
                    text={functionalities[3].value}
                    key={index}
                  />
                )
              })
            }
          </div>
        }
      </div>
    </C.Container>
  )
}