// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  background: linear-gradient(180deg, ${COLORS.white_d} 0%, ${COLORS.white_d} 45%, ${COLORS.white} 100%);
  border-radius: 10px;

  position: relative;

  &::before {
    content: "";
    margin: -5px;
    border-radius: inherit;
    background-image: linear-gradient(0deg, ${COLORS.white_d} 0%, ${COLORS.third_rgba} 100%);

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .CardContainer {
    padding: 20px;

    display: flex;
    flex-direction: column;

    &__icon {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      margin-top: -53px;
      background-color: ${COLORS.second};

      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;

      &::before {
        content: "";
        margin: -5px;
        border-radius: inherit;
        background-image: linear-gradient(0deg, ${COLORS.white_d} 0%, ${COLORS.third_rgba} 100%);

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
      }

      >div {
        width: 95%;
        height: 95%;
        border-radius: 50%;
        background: linear-gradient(180deg, ${COLORS.fourth_rgba} 0%, ${COLORS.fifth_rgba} 45%, transparent 100%);
        padding: 8px;

        img {
          width: 100%;
        }
      }
    }

    &__title {
      margin-top: 10px;
      margin-bottom: 20px;
      text-align: center;

      p {
        ${FUNC.font(18, 700)}
        line-height: 1.1;
      }
    }

    &__text {
      text-align: center;

      p {
        ${FUNC.font(14, 400, COLORS.fifth, 25)}

        @media ${SCREENS.tb_min} {
          ${FUNC.font(16, 400, COLORS.fifth, 25)}
        }
      }
    }
  }
`;