// STYLEs
import * as C from "./styles";

export default props => {
  let blockOperationClass = "BlockOperation";
  let blockOperationImageClass = "BlockOperationImage";

  if (props.rightOrder) blockOperationClass += " order-right"
  if (props.dimensionsType) blockOperationImageClass += ` ${props.dimensionsType}-image`;

  return (
    <C.Container className={blockOperationClass}>
      <div className="BlockOperationDetails">
        { props.idView &&
          <div className="BlockOperationDetails__idView">
            <p>{props.idView}</p>
          </div>
        }

        <div className="BlockOperationDetails__title">
          <p>{props.title}</p>
        </div>

        <div 
          className="BlockOperationDetails__text"
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        />
      </div>

      <div className={blockOperationImageClass}>
        <img src={props.image} alt="" />
      </div>
    </C.Container>
  )
}