// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.section`
  padding: 60px 0;
  padding-top: 120px;

  @media ${SCREENS.tb_min} {
    padding: 160px 0;
    padding-bottom: 300px;
  }

  .ContactContainer {
    max-width: 768px;
    width: 100%;
    margin:  0 auto;
    padding: 0 16px;

    &__title {
      text-align: center;
      margin-bottom: 35px;

      @media ${SCREENS.tb_min} {
        margin-bottom: 45px;
      }

      h3 {
        font-size: 7vw;
        line-height: 1.1;
        font-weight: 700;

        @media ${SCREENS.tb_min} {
          font-size: 2.5vw;
        }
      }
    }
  }
`;