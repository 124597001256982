// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .Plan-Card {
    &__Container {
      background-color: ${COLORS.white};
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
    }

    &__Header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      background-color: ${COLORS.second};

      p {
        ${FUNC.font(28, 700, COLORS.white)}
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    &__Content {
      margin-bottom: 32px;
    }

    &__Prices {
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__Value {
      color: ${COLORS.second};
      ${FUNC.font(32, 700, COLORS.first)}
    }

    &__Period {
      font-size: 16px;
    }

    &__Advantage {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      padding: 16px;
      width: 100%;
      background-color: ${COLORS.gray_l};

      &.white {
        background-color: ${COLORS.white};
      }

      &--content {
        display: flex;
        align-items: center;
        width: 220px;
        gap: 16px;
        p {
          ${FUNC.font(18, 600, COLORS.black)}
        }
      }
    }
  }
`;
