// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.section`
  margin-top: -450px;

  position: relative;
  z-index: 0;

  @media ${SCREENS.tb_min} {
    margin-top: -350px;
  }

  .ContactUsBg {
    width: 100%;
    height: 1024px;

    object-fit: cover;
    object-position: bottom;
  }

  .ContactUsContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    margin-top: -450px;
    padding: 0 20px;

    @media ${SCREENS.tb_min} {
      margin-top: -550px;
    }

    &__texts {
      margin-bottom: 45px;

      .ContactUsTitle {
        text-align: center;
        margin-bottom: 4px;

        h3 {
          font-size: 7vw;
          color: ${COLORS.white};
          font-weight: 700;
          line-height: 1.1;

          @media ${SCREENS.tb_min} {
            font-size: 2.5vw;
          }
        }
      }

      .ContactUsText {
        text-align: center;

        p {
          font-size: 4vw;
          color: ${COLORS.white};
          font-weight: 600;
          line-height: 1.4;

          @media ${SCREENS.tb_min} {
            font-size: 1.5vw;
          }
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 60px;

      position: relative;
      z-index: 1;

      @media ${SCREENS.tb_min} {
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 60px;
        justify-content: center;
      }

      .Card {
        @media ${SCREENS.tb_min} {
          width: calc(50% - 40px);
        }
      }
    }
  }
`;