// LIBs
import ReactGA from "react-ga4";

// COMPONENTs
import Button from "../../components/Button";

// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container className="AboutApp" id="AboutApp">
      <div className="AboutAppContainer">
        <div className="AboutAppContainer__title">
          { props.data.length > 0 &&
            <h2>{props.data[1].children[0][0].value}</h2>
          }
        </div>

        <div className="AboutAppContainer__details">
          { props.data.length > 0 &&
            <div className="AboutAppSubTitle">
              <h3>{props.data[1].children[0][1].value}</h3>
            </div>
          }

          { props.data.length > 0 &&
            <div 
              className="AboutAppText" 
              dangerouslySetInnerHTML={{
                __html: props.data[1].children[0][2].value,
              }}
            />
          }

          { props.data.length > 0 &&
            <div className="AboutAppButton">
              <Button 
                type="link-external" 
                label={props.data[1].children[0][3].value}
                to={props.data[1].children[0][4].value} 
                onClick={() => {
                  ReactGA.event({
                    action: "click-link",
                    category: "click",
                    label: "Go Dashboard",
                  });
                }}
              />
            </div>
          }
        </div>
      </div>
    </C.Container>
  )
}