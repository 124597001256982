// LIBs
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import jsCookie from "js-cookie";

// COMPONENTs
import PopupCookie from "./components/PopupCookie";
import PolicyCookie from "./components/PolicyCookie";

// ROUTER
import Router from "./core/Router";

const cookiePermissionName = "@mdsystemweb:cookie_permission";

function App(props) {
  const [ permissionCookie, setPermissionCookie ] = useState(false);
  const [ showPopupCookie, setShowPopupCookie ] = useState(false);
  const [ showPolicyCookie, setShowPolicyCookie ] = useState(false);

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" && 
      window.location.href.indexOf("https") === -1
    ) {
      window.location.href = "https://www.mdsystemweb.com.br/";
    }
  }, [])

  useEffect(() => {
    const CookiePermission = jsCookie.get(cookiePermissionName);

    if (CookiePermission === undefined) {
      setTimeout(() => setShowPopupCookie(true), 2500);
    }

    if ((CookiePermission !== undefined && JSON.parse(CookiePermission)) || permissionCookie) {
      // console.log("Permitido");
      // ReactGA.initialize("G-FR4Y3KEWJ1");
      ReactGA.initialize("G-R4GF665EGS");
    }
  }, [permissionCookie])

  const responseCookie = (value) => {
    jsCookie.set(cookiePermissionName, JSON.stringify(value), {
      expires: 7,
    });
    setPermissionCookie(value);
    setShowPopupCookie(false);
  }

  return (
    <div className="App">
      <Router />
      <PopupCookie active={showPopupCookie} onShow={() => setShowPolicyCookie(true)} onResponse={responseCookie} />
      <PolicyCookie active={showPolicyCookie} onHide={() => setShowPolicyCookie(false)} />
    </div>
  )
}

export default App;