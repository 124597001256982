// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.div`
  padding: 60px 0;
  background: linear-gradient(140deg, ${COLORS.first_light} -25%, ${COLORS.first} 10%, ${COLORS.first_dark} 70%);

  .FunctionalitiesContainer {
    max-width: 450px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    @media ${SIZES.tb} {
      max-width: 1024px;
    }

    &__title {
      margin-bottom: 40px;

      @media ${SIZES.dsS} {
        margin-bottom: 80px
      }

      h3 {
        ${FUNC.font('9vw', 400, '#FFF')}

        @media ${SIZES.mbB} {
          font-size: 38px;
          line-height: 40px;
        }

        @media ${SIZES.dsS} {
          font-size: 48px;
          line-height: 50px;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 60px;

      @media ${SIZES.tb} {
        flex-direction: row;
        gap: 20px;
        justify-content: space-between;
      }
    }
  }
`;
