// LIBs
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { jwtDecode } from "jwt-decode";

// COMPONENTs
import Sidebar from "../../containers/Documentation/Sidebar";
import Content from "../../containers/Documentation/Content";

// SERVICEs
import { getBlocks } from "../../core/services/Api";

// UTILs
import { convertDataPageApiInObject } from "../../core/utils/CDPAIO-1.0.0";

// STYLEs
import * as C from "./styles";

function Documentation(props) {
  const location = useLocation();
  const [ linkActive, setLinkActive ] = useState("");
  const [ documentation, setDocumentation ] = useState([]);

  useEffect(() => {
    start();
  }, [])

  useEffect(()=>{
    let pageHash = location.hash.slice(1, -1)
    
    if(!!pageHash){
      setLinkActive(pageHash)
    }else{ start() }

  },[location])

  async function start() {
    document.title = "Mdsystem Web - Documentação";
    window.scrollTo(0, 0);

    const req = await getBlocks("default");
    const reqDecoded = jwtDecode(req.data.token);

    if (req.status === 200 && reqDecoded.data.length > 0) {
      const documentacao = convertDataPageApiInObject(reqDecoded.data).documentacao;
      setDocumentation(documentacao);
      if (documentacao.length > 0) setLinkActive(documentacao[0].name_link);
      
      let pageHash = location.hash.slice(1, -1)
      
        if (!pageHash){
          setLinkActive(documentacao[0].name_link);
        }else{
          setLinkActive(pageHash)
        }
    }

    ReactGA.event({
      action: "Documentação Page Api",
      category: "load-page",
    });

    props.onHideLoad();
  }

  const documentationVisible = documentation.find(el => el.name_link === linkActive);

  return (
    <C.Container>
      <div className="DocumentationContainer">
        <Sidebar 
          activeLink={linkActive} 
          onActiveLink={(linkName) => {
            setLinkActive(linkName);
          }}
        />
        { documentationVisible &&
          <Content 
            data={documentationVisible}
          />
        }
        { !documentationVisible &&
          <p>Documentação desta aba não encontrada!</p>
        }
      </div>
    </C.Container>
  );
}

export default Documentation;
