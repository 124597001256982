// INCLUDEs
import colors from "./colors"

export default {
  font: (fontSize, fontWeight, color = colors.black, lineHeight = fontSize + 2) => {
    return `
      font-size: ${fontSize}px;
      font-weight: ${fontWeight};
      color: ${color};
      line-height: ${lineHeight}px;
    `;
  }
}