// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.seventh};

  .Page-Api-Steps {
    &__Content {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      max-width: 1280px;
      width: 100%;
      padding: 40px 16px;

      @media ${SCREENS.tb_min} {
        flex-direction: row;
        gap: unset;
        align-items: start;
      }
    }

    &__Line {
      position: relative;
      display: flex;
      border-radius: 10px;

      @media ${SCREENS.tb_min}{
        height: 80px;
        width: 100%;
        background-color: unset;
      }

      @media ${SCREENS.tbV_min} {
        height: 120px;
      }

      &:after {
        content: unset;
        background-color: ${COLORS.first};
        height: 4px;
        border-radius: 4px;
        width: 100%;
        position: absolute;
        inset: 50% auto auto 50%;
        transform: translate(-50%, -50%);

        @media ${SCREENS.tb_min} {
          content: "";
        }
      }
    }
  }
`;
