// LIBs
import styled from "styled-components";

// INCLUDEs
import { SCREENS, COLORS } from "../../../core/StyleSheets/includes";

export const Container = styled.div`
    flex: 1;
    max-width: calc(100vw - 40px);

    @media ${SCREENS.tb_min} {
        max-width: 84%;
    }

    .image-modal {
        display: none;
        background-color: #00000075;
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        align-items: center;
        justify-content: center;

        img {
            max-width: calc(100% - 32px);
            max-height: 90%;
        }

        button {
            display: block;
            position: fixed;
            top: 20px;
            right: 20px;
            z-index: 101;

            svg {
                height: 40px;
                width: 40px;
                color: #fff;
            }
        }
    }

    .DocumentationContent {
        padding: 20px 0;
        max-width: calc(100vw - 40px);

        @media ${SCREENS.tb_min} {
            padding: 20px;
            max-width: 100%;
        }

        a {
            color: blue;
            word-break: break-word;
        }

        a:hover {
            color: purple;
            cursor: pointer;
        }
        .code-wrapper{
            max-width: 100%;
            position: relative;

            button {
                position: absolute;
                top: 16px;
                right: 16px;
                border-radius: 10px;
                color: #fff;
                background-color: ${COLORS.first_d};
                height: fit-content;
                width: fit-content;

                img{
                    width: 24px;
                    height: 24px;
                }
            }

            pre {
                background-color: ${COLORS.first_d};
                padding: 16px;
                border-radius: 10px;
                overflow-x: auto;
                max-width: 100%;
                
                code {
                    display: block;
                    min-width: fit-content;
                    padding-right: 100px;
                }
    
    
                p,
                span {
                    font-size: 16px;
                }
    
                .lang-javascript {
                    color: #50ed57;
    
                    .hljs-string {
                        color: #f1fa8c;
                    }
    
                    .hljs-attr,
                    .hljs-attribute,
                    .hljs-symbol {
                        color: ${COLORS.white};
                    }
    
                    .hljs-function {
                        color: #ff79bd;
                    }
    
                    .hljs-params {
                        color: #ffb86c;
                    }
    
                    .hljs-built_in {
                        color: #bd93f9;
                    }
    
                    .hljs-comment {
                        color: ${COLORS.second_l};
                    }
                }
    
                .lang-JSON {
                    color: ${COLORS.white};
    
                    .hljs-string {
                        color: #f1fa8c;
                    }
    
                    .hljs-number,
                    .hljs-literal {
                        color: #bd93f9;
                    }
    
                    .hljs-comment {
                        color: ${COLORS.second_l};
                    }
                }
            }
        }

        img {
            max-width: 100%;

            &:hover {
                cursor: pointer;
            }
        }

        h1,
        h2,
        h3,
        h4 {
            font-weight: 700;
        }

        h1 {
            font-size: 32px;
            margin-bottom: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid #c3c3c3;
        }

        h2 {
            font-size: 26px;
            margin-bottom: 17px;
            padding-bottom: 8px;
            border-bottom: 1px solid #c3c3c3;
        }

        h3 {
            font-size: 20px;
            margin-bottom: 25px;
        }

        p,
        a {
            font-size: 16px;
            margin-bottom: 14px;
        }

        ul {
            margin-bottom: 20px;
            padding-left: 20px;
            list-style: initial;

            li {
                margin: 10px 0;
            }
        }
    }
`;
