// LIBs
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";

// COMPONENTs
import HilightItem from "../../../components/HilightItem";

// STYLEs
import * as C from "./styles";

//COMPONENTs
import Arrow from "../../../components/ArrowButton";

export default props => {
  const sliderRef = useRef();

  function next() {
    sliderRef.current.slickNext();
  }

  function previous() {
    sliderRef.current.slickPrev();
  }

  return (
    <C.Container id="hilights">
      <picture>
        <source srcSet={props.data.background} type="image/webp"/>
        <source srcSet={props.data.background_lite} type="image/png" />
        <img 
          src={props.data.background} 
          width={425}
          height={338}
          className="HilightsBg" 
          alt="Background dos Destaques" 
        />
      </picture>

      <div className="HilightsContainer">
        <div className="HilightsContainer__title">
          <h2>{props.data.title}</h2>
        </div>

        <div className="HilightsContainer__list">
          <Arrow onClick={previous} type="left" />
          <Arrow onClick={next} />
          <Slider
            ref={sliderRef}
            dotsClass="Slider-Dots"
            slidesToShow={4}
            dots={true}
            arrows={false}
            infinite={false}
            responsive={[
              {
                breakpoint: 450,
                settings: {
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                },
              },
            ]}
          >
            { (props.destaques && props.destaques.length > 0) &&
              props.destaques.map((element, index) => (
                <HilightItem 
                  title={element.title}
                  src={element.image}
                  srcLite={element.image_lite}
                  onTo={() => props.onTo(element.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/ /g, "_").toLowerCase(), element.title)}
                  key={index}
                />
              ))
            }
          </Slider>
        </div>
      </div>
    </C.Container>
  )
}