//LIBS
import React, { useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';

// STYLEs
import * as C from './styles';

const HeaderDropdown = ({ open = false, items, onClick, right, onCloseMenu }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  function handleOpenSubMenu(e) {
    if (e.target.tagName.toLowerCase() !== 'a') {
      e.preventDefault();
      setOpenSubMenu(true);
    } else {
      if (onCloseMenu) onCloseMenu();
    }
  }

  return (
    <>
      {open && (
        <C.Container alignRight={right}>
          {items.map((item, index) => (
            <div
              key={index}
              className='headerDropdown--item'
              onMouseLeave={() => setOpenSubMenu(false)}
              onClick={() => (!item.child ? onClick() : null)}>
              {item.onScrollTo ? (
                <a
                  href={`/${item.link}`}
                  onClick={item.onScrollTo}
                  aria-label='Acesse rapidamente a página correspondente do site'>
                  {item.title}
                </a>
              ) : (
                <a
                  href={item.link}
                  onClick={handleOpenSubMenu}
                  onMouseEnter={() => (window.screen.width > 768 ? setOpenSubMenu(true) : null)}
                  aria-label='Acesse rapidamente a página correspondente do site'>
                  {item.title}

                  {item.child && <FiChevronRight className={openSubMenu ? 'active' : ''} />}
                </a>
              )}

              {item.child && (
                <HeaderDropdown
                  open={openSubMenu}
                  items={item.child}
                  onClick={() => setOpenSubMenu(false)}
                  onCloseMenu={onCloseMenu}
                  right
                />
              )}
            </div>
          ))}
        </C.Container>
      )}
    </>
  );
};

export default HeaderDropdown;
