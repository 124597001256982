//LIBS
import { useEffect } from "react";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";

// STYLEs
import * as C from "./styles";

//ASSETS
import CopyIcon from "../../../assets/copy-icon.svg"

export default (props) => {
    useEffect(() => {
        setOpenAndCloseImageModal();
        addCopyButton();
    }, [props.data.about]);

    function setOpenAndCloseImageModal() {
        const imageModal = document.getElementById("image-modal");

        const imageArr = document.querySelectorAll(".DocumentationContainer img");
        imageArr.forEach((el) =>
            el.addEventListener("click", () => {
                const clonedElement = el.cloneNode(true);
                imageModal.appendChild(clonedElement);
                imageModal.style.display = "flex";
            })
        );

        imageModal.addEventListener("click", (e) => {
            if (e.target === imageModal) {
                imageModal.style.display = "none";
                const firstChild = imageModal.firstElementChild;

                while (imageModal.children.length > 1) {
                    const child = imageModal.lastElementChild;
                    if (child !== firstChild) {
                        imageModal.removeChild(child);
                    }
                }
            }
        });
    }

    function addCopyButton() {
        document.querySelectorAll("pre").forEach((el) => {
            const wrapperElement = document.createElement("div");
            wrapperElement.appendChild(el.cloneNode(true));
            wrapperElement.className = "code-wrapper";
            wrapperElement.innerHTML += `<button><img src="${CopyIcon}" alt="Icone de cópia"/></button>`;

            el.parentNode.replaceChild(wrapperElement, el);

            wrapperElement.querySelector("button").addEventListener("click", () => {
                copyCodeContent(el);
            });
        });
    }

    function copyCodeContent(el) {
        const textContent = el.textContent;

        const tempInput = document.createElement("textarea");
        tempInput.value = textContent;

        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        toast.success("Código copiado para área de transferencia!");
    }

    return (
        <C.Container>
            <div id="image-modal" className="image-modal">
                <button
                    onClick={() => {
                        document.getElementById("image-modal").click();
                    }}
                >
                    <FiX />
                </button>
            </div>
            <div
                className="DocumentationContent"
                dangerouslySetInnerHTML={{
                    __html: props.data.about,
                }}
            />
        </C.Container>
    );
};
