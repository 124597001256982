// LIBs
import styled from "styled-components";

// INCLUDEs
import { SCREENS, COLORS, FUNC } from "../../core/StyleSheets/includes";

export const Container = styled.main`
  flex: 1;
  padding-top: 65px;
  padding-bottom: 80px;

  @media ${SCREENS.tb_min} {
    padding-top: 125px;
    padding-bottom: 120px;
  }

  .DocumentationContainer {
    max-width: 1280px;
    width: 100%;
    padding: 20px 20px;
    margin: 0;
    
    @media ${SCREENS.ds_min} {
      margin: 0 auto;
    }

    display: flex;

    >p {
      padding: 20px 0;
      font-weight: 600;

      @media ${SCREENS.tb_min} {
        padding: 20px;
      }
    }
  }
`;
