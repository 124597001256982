// LIBs
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";

// CONTAINERs
import Header from "./containers/Header";
import Banner from "./containers/Banner";
import AboutApp from "./containers/AboutApp";
import Functionalities from "./containers/Functionalities";
import Operation from "./containers/Operation";
import Assessments from "./containers/Assessments";

// FORMs
import Contact from "./forms/Contact";

// HELPERs
import { smoothScrollTo } from "./core/utils/helpers";
import { getBlocksAll, getMenus } from "./core/utils/dashApi";

// STYLESHEETs
import "./core/StyleSheet/theme.css";
import 'react-toastify/dist/ReactToastify.css';

const PromotionalDash = props => {
  const [ showMenu, setShowMenu ] = useState(false);
  const [ currentSection, setCurrentSection ] = useState("");
  const [ fixedMenu, setFixedMenu ] = useState(false);
  const [ blocks, setBlocks ] = useState([]);
  const [ menus, setMenus ] = useState([]);

  useEffect(() => {
    start();

    window.addEventListener("scroll", scroll);
  }, [])

  async function start() {
    const sections = document.querySelectorAll("section");
    if (sections.length > 0) setCurrentSection(sections[0].id);

    const blocks = await getBlocksAll();
    const menus = await getMenus();

    ReactGA.event("page_view");

    if (
      blocks &&
      blocks.status === 200 && 
      blocks.data.data && 
      blocks.data.data.length > 0
    ) {
      setBlocks(blocks.data.data);
    }

    if (
      menus &&
      menus.status === 200 && 
      menus.data.data && 
      menus.data.data.length > 0
    ) {
      setMenus(menus.data.data);
    }

    props.onHideLoad();
  }

  function scroll(event) {
    const sections = document.querySelectorAll(".PromotionalDash section");
    const headerDefault = document.querySelector("header.Header");
    const header = document.querySelector(".PromotionalDash header.HeaderPromotional");

    if (sections.length > 0) {
      sections.forEach(section => {
        const startSectionY = section.offsetTop;
        const endSectionY = section.offsetTop + section.clientHeight;
        const scrollY = window.scrollY + headerDefault.clientHeight;
  
        if ((scrollY + header.clientHeight) >= startSectionY && window.scrollY < endSectionY) {
          if (section.id && section.id !== currentSection) {
            setCurrentSection(section.id);
          }
        }

        if (section.id && section.id === "Banner" && scrollY >= endSectionY) {
          setFixedMenu(true);
        } else if (section.id && section.id === "Banner" && scrollY < endSectionY) {
          setFixedMenu(false);
        }
      });
    }
  }

  function scrollTo(to) {
    const section = document.getElementById(to);
    const headerDefault = document.querySelector("header.Header");
    const header = document.querySelector("header.HeaderPromotional");
    
    ReactGA.event({
      action: "click-menu",
      category: "click",
      label: `To ${to}`,
    });

    if (section) {
      smoothScrollTo(0, (section.offsetTop - headerDefault.clientHeight - header.clientHeight + 50), 800);
    }

    setShowMenu(false);
  }

  return (
    <div className="PromotionalDash">
      <main>
        <Banner data={blocks} />
        <Header 
          data={menus}
          showMenu={showMenu} 
          currentAction={currentSection}
          fixed={fixedMenu}
          onToggleMenu={setShowMenu} 
          onToScroll={scrollTo}
        />
        <AboutApp data={blocks} />
        <section id="Functionalities">
          <Functionalities data={blocks} />
          <Operation data={blocks} />
        </section>
        {/* <Assessments data={blocks} /> */}
        <Contact data={blocks} />
      </main>
    </div>
  )
}

export default PromotionalDash;